import { Component, OnInit } from '@angular/core';
import { BaseList } from '@app/admin/base/list';
import { Const } from '@const/Const';
import { environment } from '@env/environment';
import { DateUtil } from '@services/date-utils';
import { InputHelper } from '@services/input-helper';
import { Utils } from '@services/utils';
import { ModalHelper } from '@wearewarp/ng-antd';
import { ResponseAdminFinRouteListPODConfirmationItem } from '@wearewarp/types/rest-api/admin/fin';

enum TabFilter {
  needPodConfirm = 'needPodConfirm',
  needPodUpload = 'needPodUpload',
}

interface CountTab {
  needPod?: number,
  needPodConfirm?: number,
}

@Component({
  selector: 'pod-confirmation-list',
  templateUrl: './route-list.component.html',
  styleUrls: ['./route-list.component.scss','../../list.scss']
})
export class PodConfirmationList extends BaseList<ResponseAdminFinRouteListPODConfirmationItem> {
  private _selectedTabIndex = 0;
  public countSum: CountTab = {}
  constructor(private modalHelper: ModalHelper) {
    super();
  }

  get currentTabFilter(): TabFilter {
    switch (this._selectedTabIndex) {
      case 0: return TabFilter.needPodConfirm;
      case 1: return TabFilter.needPodUpload;
    }
  }

  get actionText(){
    switch (this._selectedTabIndex) {
      case 0: return 'Confirm POD';
      case 1: return 'Upload POD';
    }
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  protected handleNavigationEnd(url: string, prevQueryParam: any): void {
    super.handleNavigationEnd(url, prevQueryParam);
    this.countAll();
  }

  onFilterTabChanged(value) {
    this._selectedTabIndex = value;
  }

  getRouterLinkDetail(item: ResponseAdminFinRouteListPODConfirmationItem) {
    let canRunPODMatching = this.shouldShowPodMatchingBtn(item);
    if(canRunPODMatching) return `${Const.routeAdminPODNeedConfirm}/${item.route.id}?runPODMatching=true`;
    return `${Const.routeAdminPODNeedConfirm}/${item.route.id}`;
  }

  public getCompletedDate(item: ResponseAdminFinRouteListPODConfirmationItem){
    if(!item?.route?.completedWhenBy?.when) return 'N/A'
    return DateUtil.formatDate(item?.route?.completedWhenBy?.when, Const.FORMAT_GUI_DATE)
  }

  public getCustomers(item: ResponseAdminFinRouteListPODConfirmationItem){
    return item?.route?.clients || [];
  }

  getCarrierNameTxt(item: ResponseAdminFinRouteListPODConfirmationItem){
    return item?.route?.carrier?.basicInfo?.name || 'N/A';
  }

  protected getApiUrl(): string {
    return this.getApiByTab(this.currentTabFilter);
  }

  protected onGetDataSucceeded(resp: any): void {
    if (resp?.data?.total) {
      this.updateCountSum(this.currentTabFilter, resp.data.total);
    }
  }

  private countAll() {
    this.countForTab(TabFilter.needPodConfirm);
    this.countForTab(TabFilter.needPodUpload);
  }

  private countForTab(tab: TabFilter) {
    const params = this.prepareParamGetList();
    const url = Utils.appendQueryStringIntoUrl(`${this.getApiByTab(tab)}?countOnly=true`, params);
    this.api.GET(url).subscribe(
      resp => {
        if (resp?.data?.total != null) {
          this.updateCountSum(tab, resp.data.total);
        }
      }, err => {
        this.showErr(err);
      }
    );
  }

  private updateCountSum(tab: TabFilter, value: number) {
    this.countSum = {...this.countSum, ...{[tab]: value}};
  }

  private getApiByTab(tab: TabFilter) {
    switch (tab) {
      case TabFilter.needPodConfirm: return Const.APIURI_POD_CONFIRMATION_V2('list_need_pod_confirm');
      case TabFilter.needPodUpload: return Const.APIURI_POD_CONFIRMATION_V2('list_need_pod_upload');
    }
  }

  public sortCompleteDate = (a: ResponseAdminFinRouteListPODConfirmationItem, b: ResponseAdminFinRouteListPODConfirmationItem) => {
    let dateA = a?.route?.completedWhenBy?.when ? new Date(a?.route?.completedWhenBy?.when) : new Date();
    let dateB = b?.route?.completedWhenBy?.when ? new Date(b?.route?.completedWhenBy?.when) : new Date();
    return dateA.getTime() - dateB.getTime();
  }

  public onAnalyzing = {};
  private readonly SUGGEST_POD_IGNORE_CLIENTIDS = [
    '01H22NK3MBXBDCW6AZDWFZ09V0',     // walmart
    '01H22NK3MNSGA6HTPAVVEYDAN9',     // Saltbox
    '01H22NK3KK222CH8CKCDTTJ82B',      // Shef
    '01HRZEJV34B82ZVT2B1TE06VWX',     // GoPuff
    '01J2CW1K337KRCZCV8NPS19VSC',     // GoPuff (Bevmo!)
  ]

  isPodAnalyzing(jobId): boolean {
    return this.onAnalyzing[jobId] === true ? true : false;
  }

  shouldShowPodMatchingBtn(item) {
    // if (this._selectedTabIndex !== 0) return false;
    let clients = this.getCustomers(item);
    let shouldShow = true;
    for (let client of clients) {
      let clientId = client.id || '';
      if (this.SUGGEST_POD_IGNORE_CLIENTIDS.includes(clientId)) {
        shouldShow = false;
        break;
      }
    }
    return shouldShow;
  }

  onBtnAnalyzePodMatchWithShipment(item) {
    let jobId = item?.route?.id || '';
    this.onAnalyzing[jobId] = true;
    let url = `${environment.backendUrl}/v1/textract/suggest-pod-match-with-shipment`;
    let params = { jobId };
    this.api.POST(url, params).subscribe(
      resp => {
        this.showSuccess(`Route ${item.route.code} successful.`);
        this.onAnalyzing[jobId] = false;
      },
      err => {
        this.showErr(err);
        this.onAnalyzing[jobId] = false;
      }
    )
  }
}
